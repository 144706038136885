function Footer() {
  return (
    <footer className="attribution">
      <p>
        developed by{" "}
        <a href="https://github.com/donnermax" target="_blank" rel="noreferrer">
          Max Donner
        </a>
      </p>
    </footer>
  );
}

export default Footer;
